<template>
  <div class="admin">
    <h1 class="h1_title">
      <img src="../../assets/images/company/nav_admin.png">管理员管理
    </h1>
    <div class="move-up"></div>
    <div class="flex-bet">
      <div class="super">
        <h2 class="flex-bet-cen">
          超级管理员<span @click="changeShow=true">更换</span>
        </h2>
        <div class="flex">
          <img :src="superAdmin.avatar||require('../../assets/images/head.png')" class="headImg">
          <div class="msg">
            <h3 class="flex">{{superAdmin.realname||superAdmin.nickname}}<span>{{superAdmin.tier_name}}</span></h3>
            <div class="tel flex--cen"><img src="../../assets/images/login/icon-phone.png">{{superAdmin.encryp_mobile}}</div>
          </div>
          <div class="lasttime">
            最近登录：{{$formatTime(superAdmin.last_login_time,'y-m-d h:m')}}
          </div>
        </div>
      </div>
      <div class="super">
        <h2 class="flex-bet">
          企业账号：{{accountAdmin.account}}<span @click="passShow=true">修改密码</span>
        </h2>
        <div class="flex" style="height:42px">
          <!-- <img :src="accountAdmin.avatar||require('../../assets/images/head.png')" class="headImg"> -->
          <div class="msg">
            <h3 class="flex"><span>{{accountAdmin.tier_name}}</span></h3>
            <div class="tel flex--cen" v-if="accountAdmin.encryp_mobile"><img src="../../assets/images/login/icon-phone.png">{{accountAdmin.encryp_mobile}}</div>
          </div>
          <div class="lasttime">
            最近登录：{{$formatTime(accountAdmin.last_login_time,'y-m-d h:m')}}
          </div>
        </div>
      </div>
    </div>
    <div class="table-btn">
      <div class="btn" @click="addShow=true">添加</div>
      <div class="btn del" @click="delConfirm('')">解除</div>
    </div>

    <el-table
      ref="table"
      :data="adminList"
      tooltip-effect="dark"
      height="calc(100vh - 413px)"
      style="width:100%">
      <el-table-column type="selection" width="50" v-if="adminList.length"></el-table-column>

      <el-table-column label="姓名" min-width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="table-head">
            <img :src="scope.row.avatar || require('../../assets/images/head.png')">
            <span>{{scope.row.realname || scope.row.nickname}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="country_code" label="区号"></el-table-column>

      <el-table-column prop="mobile" label="手机号" min-width="120"></el-table-column>

      <el-table-column prop="manager_type" label="身份" min-width="100"></el-table-column>

      <el-table-column label="最后登录" min-width="140">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.last_login_time,'y-m-d h:m')||'-'}}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="操作" min-width="170" align="center">
        <template slot-scope="scope">
          <el-tooltip 
            :disabled="tooltipHide"
            effect="dark"
            content="修改姓名和权限"
            placement="top"
            :open-delay="400">
            <el-button @click="modifyConfirm(scope.row,scope.$index)">修改信息</el-button>
          </el-tooltip>
          <span class="btn red" @click="delConfirm(scope.row)">解除管理</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <pagination ref="adminPage" @change="getAdminList" :total="total"></pagination>

    <!-- 添加管理员弹窗 -->
    <el-dialog
      title="添加管理员"
      :visible.sync="addShow"
      :close-on-click-modal="false"
      @close="closeAdd"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="top-nav">
          <span :class="{current:addType==1}" @click="toggle(1)">手机号添加</span>
          <span :class="{current:addType==2}" @click="toggle(2)">从员工列表添加</span>
        </div>

        <template v-if="addType==1">
          <div class="input-row">
            <h4>姓名</h4>
            <div class="box" :class="{error:errorType==1}">
              <input 
                class="input"
                placeholder='请输入真实姓名' 
                maxlength="10"
                v-model="newName"
                @input="inputChange($event,'newName')"
                ref="newName">
              <div class="input-icon icon-clear" title="清空" v-show="newName" @click="newName=''"></div>
              <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
            </div>
          </div>
          <div class="input-row">
            <h4>手机号</h4>
            <div class="box" :class="{error:errorType==2}">
              <el-select class="el-select-code" v-model="country_code" @change="selectcountry_code">
                <el-option
                  v-for="(item,key) in $countryList"
                  :key="key"
                  :label="item.label"
                  :value="item.country_code">
                </el-option>
              </el-select>
              <input 
                class="input"
                placeholder='请输入手机号'
                @input="inputChange($event,'newMobile')"
                maxlength="11"
                v-model="newMobile"
                ref="newMobile">
              <div class="input-icon icon-clear" title="清空" v-show="newMobile" @click="newMobile=''"></div>
              <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
            </div>
          </div>
          <div class="input-row">
            <h4>短信验证码</h4>
            <div class="box">
              <input 
                class="input"
                placeholder='请输入验证码' 
                maxlength="6" 
                v-model="newCaptcha" 
                @input="inputChange($event,'newCaptcha')" 
                ref="newCaptcha">
              <button 
                @click="getCode('add_administrator')" 
                class="btnCode" 
                :class="{disabled: disabled}"
                :disabled='disabled'>
                <b :style="{'transition-duration': disabled ? totalTime+'s' : '0s'}"></b>
                <span>{{disabled ? `重新发送（${time}s）` : '发送验证码'}}</span>
              </button>
              <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
            </div>
          </div>
          <div class="input-row authority">
            <h4>权限分配</h4>
            <el-tree
              :data="authorityList"
              @check-change="setError"
              ref="mobileTree"
              show-checkbox
              node-key="id">
            </el-tree>
          </div>
        </template>
        <template v-else>
          <div class="adminAddMember">

            <div class="header">
              <input type="text" placeholder="搜索" class="search-input" @input="search">
              <span v-show="selectedList.length">{{selectedList.length}} / {{addAdminTotal}}</span>
            </div>
            
            <el-table
              v-loading="addMemberloading"
              ref="addTable"
              class="addTable"
              :row-class-name="getClass"
              :data="addAdminList"
              tooltip-effect="dark"
              @selection-change="selectAdmin"
              @row-click="pickRow">
              <el-table-column :selectable="checkPick" type="selection" width="50"></el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <div class="table-head">
                    <img :src="scope.row.avatar || require('../../assets/images/head.png')">
                    <span>{{scope.row.realname || scope.row.nickname}}</span>
                    <span>-</span>
                    <span>{{scope.row.mobile}}</span>
                    <span v-if="scope.row.is_administrator" style="font-size:12px">（该用户已管理其他企业）</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-tag
              v-for="(item,i) in selectedList"
              :key="i"
              :disable-transitions="false"
              :type="item.type"
              @close="closeTag(item,i)"
              closable>
              {{item.realname || item.nickname}}
            </el-tag>
            
            <div class="input-row authority">
              <h4>权限分配</h4>
              <el-tree
                :data="authorityList"
                :class="{err_tree:errorType==11}"
                @check-change="setError"
                ref="staffTree"
                show-checkbox
                node-key="id">
              </el-tree>
            </div>

          </div>
        </template>

        <div class="bottom-btn" @click="submitAdmin">确 认</div>
      </div>
    </el-dialog>
    <!-- 更换超级管理员弹窗 -->
    <el-dialog
      title="更换超级管理员"
      :visible.sync="changeShow"
      :close-on-click-modal="false" 
      @close="closeChange"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="top-step flex-bet-cen">
          <span :class="{curr:step==1}">① 当前超级管理员手机号验证</span>
          <span class="dashed"></span>
          <span :class="{curr:step==2}">② 新超级管理员信息验证</span>
        </div>
        <template v-if="step==1">
          <div class="input-row">
            <h4>姓名</h4>
            <div class="box gray">{{superAdmin.realname||superAdmin.nickname}}</div>
          </div>
          <div class="input-row">
            <h4>手机号</h4>
            <div class="box gray">{{superAdmin.encryp_mobile}}</div>
          </div>
          <div class="input-row">
            <h4>短信验证码</h4>
            <div class="box" :class="{error:errorType==4}">
              <input 
                class="input"
                placeholder='请输入验证码' 
                maxlength="6" 
                v-model="oldCaptcha" 
                @input="inputChange($event,'oldCaptcha')" 
                ref="oldCaptcha">
              <button
                @click="getOldCode"
                class="btnCode"
                :class="{disabled: disabled1}"
                :disabled='disabled1'>
                <b :style="{'transition-duration': disabled1 ? totalTime+'s' : '0s'}"></b>
                <span>{{disabled1 ? `重新发送（${time1}s）` : '发送验证码'}}</span>
              </button>
              <div class="errText" v-show="errorType==4">{{errorMsg}}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="input-row">
            <h4>新管理员姓名</h4>
            <div class="box" :class="{error:errorType==1}">
              <input
                class="input"
                placeholder='请输入管理员姓名'
                @input="inputChange($event,'newName')"
                maxlength="11"
                v-model="newName"
                ref="newName">
              <div class="input-icon icon-clear" title="清空" v-show="newName" @click="newName=''"></div>
              <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
            </div>
          </div>
          <div class="input-row">
            <h4>手机号</h4>
            <div class="box" :class="{error:errorType==2}">
              <el-select v-model="country_code" @change="selectcountry_code" class="el-select-code">
                <el-option
                  v-for="(item,key) in $countryList"
                  :key="key"
                  :label="item.label"
                  :value="item.country_code">
                </el-option>
              </el-select>
              <input 
                class="input"
                placeholder='请输入手机号'
                @input="inputChange($event,'newMobile')"
                maxlength="18"
                v-model="newMobile"
                ref="newMobile">
              <div class="input-icon icon-clear" title="清空" v-show="newMobile" @click="newMobile=''"></div>
              <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
            </div>
          </div>
          <div class="input-row">
            <h4>短信验证码</h4>
            <div class="box" :class="{error:errorType==3}">
              <input 
                class="input"
                placeholder='请输入验证码' 
                maxlength="6" 
                v-model="newCaptcha"
                @input="inputChange($event,'newCaptcha')"
                ref="newCaptcha">
              <button 
                @click="getCode('new_administrator')"
                class="btnCode" 
                :class="{disabled: disabled}"
                :disabled='disabled'>
                <b :style="{'transition-duration': disabled ? totalTime+'s' : '0s'}"></b>
                <span>{{disabled ? `重新发送（${time}s）` : '发送验证码'}}</span>
              </button>
              <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
            </div>
          </div>
        </template>
        <div class="bottom-btn" @click="changeAdmin">确 认</div>
      </div>
    </el-dialog>
    <!-- 解除管理员弹窗 -->
    <el-dialog
      title="解除管理员"
      class="el-dialog-confirm"
      :visible.sync="delShow"
      :close-on-click-modal="false"
      center>
      <span v-if="delArr.length==1">解除后此账号将无法登录企业控制台，确认解除管理员 [{{delArr[0].realname || delArr[0].nickname}}] ？</span>
      <span v-else>解除后此账号将无法登录企业控制台，<br>确认解除这<b> {{delArr.length}} </b>位管理员？</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="del">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改密码弹窗 -->
    <el-dialog
      title="修改密码"
      :visible.sync="passShow"
      :close-on-click-modal="false" 
      @close="closeChange"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row">
          <h4>旧密码</h4>
          <div class="box" :class="{error:errorType==5}">
            <input
              class="input"
              placeholder='请输入旧密码'
              @input="inputChange($event,'oldPw')"
              :type="pw1?'text':'password'"
              maxlength="11"
              v-model="oldPw"
              ref="oldPw">
            <div class="input-icon" :class="pw1?'icon-hide':'icon-show'" :title="pw1?'隐藏密码':'显示密码'" v-show="oldPw" @click="pw1=!pw1"></div>
            <div class="errText" v-show="errorType==5">{{errorMsg}}</div>
          </div>
        </div>
        <div class="input-row">
          <h4>新密码</h4>
          <div class="box" :class="{error:errorType==6}">
            <input 
              class="input"
              placeholder='请输入新密码'
              @input="inputChange($event,'newPw')"
              :type="pw2?'text':'password'"
              maxlength="18"
              v-model="newPw"
              ref="newPw">
            <div class="input-icon" :class="pw2?'icon-hide':'icon-show'" :title="pw2?'隐藏密码':'显示密码'" v-show="oldPw" @click="pw2=!pw2"></div>
            <div class="errText" v-show="errorType==6">{{errorMsg}}</div>
          </div>
        </div>
        <div class="input-row">
          <h4>确认新密码</h4>
          <div class="box" :class="{error:errorType==7}">
            <input 
              class="input"
              placeholder='请再次确认'
              @input="inputChange($event,'againPw')"
              :type="pw3?'text':'password'"
              maxlength="18"
              v-model="againPw"
              ref="againPw">
            <div class="input-icon" :class="pw3?'icon-hide':'icon-show'" :title="pw3?'隐藏密码':'显示密码'" v-show="oldPw" @click="pw3=!pw3"></div>
            <div class="errText" v-show="errorType==7">{{errorMsg}}</div>
          </div>
        </div>
        <div class="bottom-btn" @click="changePassword">确 认</div>
      </div>
    </el-dialog>
    <!-- 修改管理员权限和姓名 -->
    <el-dialog
      title="修改信息"
      :visible.sync="modifyShow"
      :close-on-click-modal="false"
      @close="closeChange"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row">
          <h4>姓名</h4>
          <div class="box" :class="{error:errorType==8}">
            <input 
              class="input"
              placeholder='请输入需要修改的姓名' 
              maxlength="30"
              @input="inputChange($event)"
              v-model="modifyName"
              ref="modifyName">
            <div class="input-icon icon-clear" title="清空" v-show="modifyName" @click="modifyName=''"></div>
            <div class="errText" v-show="errorType==8">{{errorMsg}}</div>
          </div>
        </div>
        <div class="input-row authority">
          <h4>权限分配</h4>
          <el-tree
            :default-checked-keys="checkedList" 
            :data="authorityList"
            :class="{err_tree:errorType==12}"
            :default-expand-all="true"
            @check-change="setError"
            ref="modifyTree"
            show-checkbox
            node-key="id">
          </el-tree>
        </div>
        <div class="bottom-btn" @click="modify">确 认</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../assets/part/page'
export default {
  components: {pagination},
  data(){
    return {
      tooltipHide: false,
      addMemberloading: false,
      reg: this.$CNReg,         // 当前区号的手机号正则
      newName: '',              // 姓名
      country_code: '86',       // 区号
      newMobile: '',            // 手机号码
      newCaptcha: '',           // 新验证码
      oldCaptcha: '',         // 旧验证码
      salt: '',                 // 更改管理员的密钥
      changeShow: false,
      step: 1,

      errorMsg: '',             // 输入框错误信息
      errorType: 0,             // 0：正常无错误，1：姓名为空，2：手机号错误，3：验证码错误

      timer: '',                // 验证码定时器
      disabled: false,          // 发送验证码按钮是否可点击
      totalTime: 60,            // 设置多少秒内可以重发验证码
      time: 0,                  // 验证码重发的剩余时间

      timer1: '',                // 前超管的：验证码定时器
      disabled1: false,          // 前超管的：发送验证码按钮是否可点击
      time1: 0,                  // 前超管的：验证码重发的剩余时间

      addType: 1,               // 添加管理员方式（1：手机号添加；2：员工列表添加）
      addShow: false,           // 添加管理员弹窗
      selectedList: [],            // 可选列表选取的管理员
      delShow: false,           // 删除确认框
      delArr: [],               // 准备删除的列表
      superAdmin: {
        realname: '',nickname: '',mobile: '',last_login_time: '',
        tier_name: '',avatar: '',user_id: '',
      },
      accountAdmin: {
        realname: '',nickname: '',mobile: '',last_login_time: '',
        tier_name: '',avatar: '',user_id: '',
      },
      adminList: [],
      authorityList: [{
        id: 0,
        label: '全部产品',
        children: [],
      }],
      
      addAdminList: [],
      addAdminTotal: 0,
      keyword: '',

      modifyShow: false,
      modifyName: '',
      modifyIndex: 0,
      checkedList: [],
      
      passShow: false,
      oldPw: '',
      newPw: '',
      againPw: '',
      pw1: false,
      pw2: false,
      pw3: false,
      //分页
      total: 5,
    }
  },
  mounted(){
    this.getAdminList()
    //获取权限列表
    this.$request({
        url: '/CompanyAdministrator/getManageTool',
        success: (data) => {
          if (data.code == 0) {
            this.authorityList[0].children = data.data
          }
        },
        error: () => {},
      })
  },
  methods: {
    setError(type = 0, msg = ''){
      this.errorType = type
      this.errorMsg = msg
    },
    getAdminList(per_page, page){
      let ref = this.$refs.adminPage
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$request({
        method: 'post',
        url: '/CompanyAdministrator/index',
        data: {
          // keyword: '',
          per_page: per_page,
          page: page,
        },
        success: (data) => {
          if (data.code == 0) {
            this.superAdmin = data.data.suprer_administrator
            this.accountAdmin = data.data.company_administrator
            let admin = data.data.general_administrator
            this.total = admin.total
            for(let item of admin.data) {
              item.manager_tool_id = item.manager_tool_id.split(',')
            }
            this.adminList = admin.data
          } else if (data.code == 101) {
            this.$router.replace({path:'/login',query:{back:this.$route.path}})
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
      })
    },
    //获取可选的员工列表
    getChooseAdmin(){
      this.$request({
        method: 'post',
        url: '/CompanyAdministrator/getChooseAdministrator',
        data: {keyword: this.keyword},
        success: (data) => {
          if (data.code == 0) {
            this.addAdminList = data.data
            if(this.addAdminTotal == 0) this.addAdminTotal = data.data.length
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
      })
    },
    search(e){
      this.keyword = e.target.value
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getChooseAdmin()
      }, 600)
    },
    // 切换 手机号添加/员工列表添加
    toggle(type){
      if (this.addType == type)  return;
      this.addType = type
      this.errorType = 0
      this.newMobile = ''
      this.newCaptcha = ''
      this.newName = ''
      this.selectedList = []
      if (type == 1) {

      } else {
        this.getChooseAdmin()
      }
    },
    getClass({row}){
      if (row.is_administrator)
        return 'unselect';
    },
    checkPick(row){
      return row.is_administrator < 1
    },
    // 点击tr整行选择
    pickRow(row){
      if(row.is_administrator==0)
        this.$refs.addTable.toggleRowSelection(row);
    },
    // 选择区号筛选手机号正则
    selectcountry_code(key){
      this.$countryList.forEach((item)=>{
        if(item.country_code==key){
          this.reg = this[item.key]
          return
        }
      })
    },
    // 监听输入内容
    inputChange(e, type){
      if (type == 'newMobile') {
        this.newMobile = e.target.value.replace(/\D/g,'')
      } else if (type=='oldPw'||type=='newPw'||type=='againPw') {
        this[type] = e.target.value.replace(/[^\w\/]/g,'')
      } else if (type == 'oldCaptcha') {
        this.oldCaptcha = e.target.value.replace(/\D/g,'')
      } else if (type == 'newMobile') {
        this.newMobile = e.target.value.replace(/\D/g,'')
      } else if (type == 'newCaptcha') {
        this.newCaptcha = e.target.value.replace(/\D/g,'')
      }
      this.errorType = 0;
    },
    // 获取验证码
    getCode(codeType){
      if (!this.newName) {
          this.errorMsg = '请先输入姓名';
          this.errorType = 1;
          this.$refs.newName.focus()
        } else if (!this.newMobile) {
          this.errorMsg = '请先输入手机号码';
          this.errorType = 2;
          this.$refs.newMobile.focus()
        } else if (!this.reg.test(this.newMobile)) {
          this.errorMsg = '手机号码格式有误';
          this.errorType = 2;
          this.$refs.newMobile.focus()
        } else {
          this.$http.post('/CompanyAdministrator/checkMobileAdministrator',{
            mobile: this.newMobile,
            country_code: this.country_code
          }).then( ({data}) => {
            if (data.code == 0) {
              this.$request({
                method: 'post',
                url: '/Sms/getSmsCaptcha',
                data: {
                  type: codeType,
                  country_code: this.country_code,
                  mobile: this.newMobile,
                },
                success: (data) => {
                  if (!data.code) {
                    this.$message({duration: 3000,message: '发送成功，请注意查收',type: 'success'})
                    this.disabled = true
                    this.time = this.totalTime;
                    this.timer = setInterval( ()=> {
                      this.time--;
                      if (this.time < 0) {
                        clearInterval(this.timer)
                        this.disabled = false
                      }
                    }, 1000)
                  } else {
                    this.$message({duration:1000,message:data.msg,type: 'error'})
                  }
                },
              })
            } else if (data.code == 2) {
              this.setError(2, '此手机号已是管理员')
            } else if (data.code == 3) {
              this.setError(2, '此手机号是其他企业管理员，不可重复添加')
            } else {
              this.$message({duration: 1500, message: data.msg})
            }
          }, () => {
            this.$message({duration: 2000, type:'error', message: '网络异常'})
          })
        }
    },
    getOldCode(){
      this.$request({
        method: 'post',
        url: '/Sms/getSmsCaptcha',
        data: {
          type: 'check_administrator',
          country_code: this.superAdmin.country_code,
          mobile: this.superAdmin.mobile,
        },
        success: (data) => {
          if (!data.code) {
            this.$message({duration: 3000,message: '发送成功，请注意查收',type: 'success'})
            this.disabled1 = true
            this.time1 = this.totalTime;
            this.timer1 = setInterval( ()=> {
              this.time1--;
              if (this.time1 < 0) {
                clearInterval(this.timer)
                this.disabled1 = false
              }
            }, 1000)
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
      })
    },
    changePassword(){
      if (!this.oldPw) {
        this.setError(5, '请先输入旧密码')
        return
      } else if (!this.newPw) {
        this.setError(6, '请先输入新密码')
        return
      } else if (!this.againPw) {
        this.setError(7, '请先输入确认密码')
        return
      } else if (this.newPw != this.againPw) {
        this.setError(7, '请确认新密码是否一致')
        return
      }
      this.$http.post('/CompanyAdministrator/baseLastPwdSetManageNewPassword', {
        company_user_id: this.accountAdmin.user_id,
        lastpassword: this.oldPw,
        newpassword: this.newPw,
        renewpassword: this.againPw,
      }).then( ({data}) => {
        if (!data.code) {
          this.passShow = false
          this.$message({duration: 1500,message:'密码修改成功', type:'success'})
        } else {
          this.$message({duration: 1500,message: data.msg, type:'error'})
        }
      }, () => {
        this.$message({duration: 1000,type:'error', message: '网络异常'})
      })
    },
    // 更换超管
    changeAdmin(){
      if (this.step == 1) {
        // this.step = 2; return;
        if(!this.oldCaptcha){
          this.setError(4, '请先输入验证码')
          return
        }
        this.$http.post('/CompanyAdministrator/checkSuperAdministrator', {
          mobile: this.superAdmin.mobile,
          captcha: this.oldCaptcha,
        }).then( ({data}) => {
          if (!data.code) {
            this.step = 2
            this.salt = data.data.salt
          } else {
            this.$message({duration: 1500,message: data.msg, type:'error'})
          }
        }, () => {
          this.$message({duration: 1000,type:'error', message: '网络异常'})
        })
      } else { //步骤2
        if (!this.newName) {
          this.setError(1, '请先输入姓名')
          return
        } else if (!this.newMobile) {
          this.setError(2, '请先输入手机号码')
          return
        }  else if (!this.reg.test(this.newMobile)) {
          this.setError(2, '手机号码格式有误')
          return
        } else if(!this.newCaptcha){
          this.setError(3, '请先输入验证码')
          return
        }
        this.$http.post('/CompanyAdministrator/changeSuperAdministrator', {
          country_code: this.country_code,
          mobile: this.newMobile,
          captcha: this.newCaptcha,
          realname: this.newName,
          salt: this.salt,
          super_user_id: this.superAdmin.user_id,
        }).then( ({data}) => {
          if (!data.code) {
            this.$message({duration: 1500,type:'success', message: '更换成功'})
            this.getAdminList()
          } else {
            this.$message({duration: 1500,message: data.msg})
          }
          this.changeShow = false
          this.newName = ''
          this.newMobile = ''
          this.newCaptcha = ''
          this.step = 1
        }, () => {
          this.$message({duration: 2000,type:'error', message: '网络异常，请稍后再试'})
        })
      }
    },
    // 员工列表管理员
    selectAdmin(row){
      this.selectedList = row
    },
    // 关闭选中标签
    closeTag(row,index){
      this.$refs.addTable.toggleRowSelection(row, false);
    },
    // 添加管理员提交
    submitAdmin(){
      if (this.hadClickAdd){
        return
      } else if (this.addType == 1) {
        let mobileTreeList = this.$refs.mobileTree.getCheckedKeys()
        if (!this.newName) {
          this.setError(1, '请先输入姓名')
          this.$refs.newName.focus()
          return
        } else if (!this.newMobile) {
          this.setError(2, '请先输入手机号码')
          this.$refs.newMobile.focus()
          return
        } else if (!this.reg.test(this.newMobile)){
          this.setError(2, '手机号码格式不对')
          this.$refs.newMobile.focus()
          return
        } else if (!this.newCaptcha) {
          this.setError(3,'请先输入验证码')
          return
        } else if (mobileTreeList.length == 0) {
          this.setError(10)
          return
        } else {
          // 检测手机号是否可添加
          this.hadClickAdd = true
          this.$http.post('/CompanyAdministrator/checkMobileAdministrator',{
            mobile: this.newMobile,
            country_code: this.country_code
          }).then( ({data}) => {
            if (data.code == 0) {
              // 添加管理员
              this.$http.post('/CompanyAdministrator/baseMobileAddAdministrator', {
                mobile: this.newMobile,
                country_code: this.country_code,
                captcha: this.newCaptcha,
                realname: this.newName,
                tool_ids: mobileTreeList.join(',')
              }).then(({data}) => {
                this.hadClickAdd = false
                if (!data.code) {
                  this.getAdminList()
                  this.addShow = false
                  this.$message({duration: 1500, type:'success', message: '添加成功'})
                } else {
                  this.$message({duration: 1500, message: data.msg})
                }
              }, () => {
                this.hadClickAdd = false
                this.$message({duration: 1000, type:'error', message: '网络异常'})
              })
            } else if (data.code == 2) {
              this.setError(2, '此手机号已是管理员')
            } else if (data.code == 3) {
              this.setError(2, '此手机号是其他企业管理员，不可重复添加')
            } else {
              this.$message({duration: 1500, message: data.msg})
            }
          }, () => {
            this.$message({duration: 2000, type:'error', message: '网络异常'})
          })
        }
      } else if (this.addType == 2) {
        let staffTreeList = this.$refs.staffTree.getCheckedKeys()
        if (this.selectedList.length == 0) {
          this.messageTips ? this.messageTips.close():'';
          this.messageTips = this.$message({duration: 2000,type:'warning', message: '请先添加管理员'})
        } else if (staffTreeList.length == 0) {
          this.setError(11)
          return
        }  else {
          let userId = ''
          this.selectedList.forEach( item => userId += `${item.user_id},` )
          this.$http.post('/CompanyAdministrator/baseEmployeeAddAdministrator',{
            emp_userid: userId.substring(0,userId.length-1),
            tool_ids: staffTreeList.join(',')
          }).then( ({data}) => {
            if (data.code == 0) {
              this.getAdminList()
              this.addShow = false
              this.$message({duration: 1500, type:'success', message: '添加成功'})
            } else {
              this.$message({duration: 1500, message: data.msg})
            }
          }, () => {
            this.$message({duration: 1000, type:'error', message: '网络异常'})
          })
        }
      }
    },
    // 操作确认
    delConfirm(e){ 
      if (e) {
        this.delArr = [e]
        this.delShow = true
      } else if (this.$refs.table.selection.length) {
        this.delArr = this.$refs.table.selection
        this.delShow = true
      } else {
        this.$message({duration:2000,message:'请选择管理员',type:'warning'})
      }
    },
    // 解除管理员
    del(){
      let userId = ''
      this.delArr.forEach( item => userId += `${item.user_id},` )
      this.$http.post('/CompanyAdministrator/removeAdministrator',{
        user_id: userId.substring(0,userId.length-1),
      }).then( ({data}) => {
        if (!data.code) {
          this.$message({duration: 1500, message: '解除成功', type:'success'})
          this.delShow = false
          for(let i in this.delArr)
            for(let j in this.adminList)
              if (this.delArr[i].id == this.adminList[j].id) {
                this.adminList.splice(j,1);
                this.delShow = false
                break;
              }
        } else {
          this.$message({duration: 2000,message: data.msg})
        }
      }, () => {
        this.$message({duration: 1000, type:'error', message: '网络异常'})
      })
    },
    //修改信息
    modifyConfirm(row,index){
      this.tooltipHide = true
      this.modifyShow = true
      this.modifyName = row.realname || row.nickname
      this.checkedList = row.manager_tool_id
      this.modifyIndex = index
    },
    modify(){
      let modifyTree = this.$refs.modifyTree.getCheckedKeys()
      if (!this.modifyName) {
        this.setError(8, '请先输入姓名')
      } else if (modifyTree.length == 0) {
        this.setError(12)
      } else {
        let user = this.adminList[this.modifyIndex]
        this.$http.post('/CompanyAdministrator/updatInfo',{
          user_id: user.user_id,
          realname: this.modifyName ,
          tool_ids: modifyTree.join(',')
        }).then( ({data}) => {
          if (!data.code) {
            user.realname = this.modifyName
            user.manager_tool_id = modifyTree
            this.modifyShow = false
            this.$message({type:'success',message:'修改成功'});
          } else {
            this.$message({message:data.msg,type:'error'})
          }
        }, () => {
          this.$message({type:'error',message:'网络异常'})
        })
      }
    },
    closeAdd(){
      this.addType = 1
      this.selectedList = []
      this.addAdminTotal = 0
      this.hadClickAdd = false
      this.keyword = ''
      this.newName = ''
      this.newMobile = ''
      this.newCaptcha = ''
      this.setError()
      this.$refs.mobileTree && this.$refs.mobileTree.setCheckedKeys([])
      this.$refs.staffTree && this.$refs.staffTree.setCheckedKeys([])
    },
    closeChange(){
      this.errorType = 0
      this.newMobile = ''
      this.newName = ''
      this.oldPw = ''
      this.newPw = ''
      this.againPw = ''
      this.modifyName = ''
      this.$refs.modifyTree && this.$refs.modifyTree.setCheckedKeys([])
    },
  },
}
</script>

<style lang="scss">
.admin{
  .flex-bet .super:nth-child(2){margin-left: 20px}
  .super{
    flex: 1;
    box-sizing: border-box;
    background: #f8f9fb;
    border: 1px solid #EBEEF5;
    padding: 20px 25px;
    color: #333333;
    margin: 30px 0 5px;
    h2{
      font-size: 16px;
      margin-bottom: 20px;
      line-height: 22px;
      span{
        color: #6b6be5;
        font-size: 14px;
        font-weight: normal;
        cursor: pointer;
        white-space: nowrap;
      }
    }
    .headImg{
      width: 40px;
      height: 40px;
      box-shadow: 0 0 2px rgba(50,50,50,.1);
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }
    .msg{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3{
        font-size: 16px;
        align-items: center;
        height: 22px;
        white-space: nowrap;
        span{
          font-size: 12px;
          margin-left: 10px;
          font-weight: normal;
        }
      }
      .tel{
        height: 20px;
        font-size: 12px;
        color: #6b6be5;
        img{
          width: 16px;
          height: 16px;
          margin-right: 4px;
          margin-top: 1px;
        }
      }
    }
    .lasttime{
      font-size: 14px;
      color: #b5b5b5;
      align-self: flex-end;
    }
  }
  .total{
    font-size: 14px;
    text-align: right;
    margin-top: 20px;
  }
  .el-dialog *{transition: height .1s}
  .authority{
    align-items: flex-start;
    h4{line-height: 26px;}
    .el-tree{
      user-select: none;
      flex: 1;
      position: relative;
    }
    .err_tree::after{
      content: '请选择权限分配';
      position: absolute;
      top: 100%;
      left: 95px;
      right: 0;
      color: #ff6265;
      font-size: 12px;
    }
  }
}

@media (max-width: 1040px) {
  .admin .super .lasttime{display: none}
}
</style>