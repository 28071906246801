<template>
  <div class="staff">
    <h1 class="h1_title">
      <img src="../../../assets/images/company/nav_staff.png">员工管理
    </h1>
    <div class="move-up"></div>
    <div class="table-btn">
      <input type="text" placeholder="搜索" class="search-input" @input="searchMember">
      <b style="flex:1"></b>
      <div class="btn" @click="inviteShow=true">邀请成员</div>
      <div class="btn" @click="uploadShow=true">导入成员</div>
    </div>
    <el-table
      v-loading="memberLoading"
      :data="memberList"
      tooltip-effect="dark"
      height="calc(100vh - 238px)"
      style="width: 100%">

      <el-table-column label="姓名" width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="table-head">
            <img :src="scope.row.avatar || require('../../../assets/images/head.png')">
            <span>{{scope.row.realname}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="性别" width="50" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.sex==2">女</span>
          <span v-else>男</span>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="country_code" label="区号" min-width="50" align="center"></el-table-column> -->
      
      <el-table-column label="手机号" min-width="110">
        <template slot-scope="scope">
          <span>{{$mobileToStar(scope.row.mobile)}}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="生日" min-width="94">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.birthday,'y-m-d')||'-'}}</span>
        </template>
      </el-table-column>

      <el-table-column label="职位类型" min-width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.type_name">{{scope.row.type_name}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column label="层级" min-width="90">
        <template slot-scope="scope">
          <span v-if="scope.row.tier_name">{{scope.row.tier_name}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column label="所属架构" width="120" align="center">
        <template slot-scope="scope">
          <span class="btn"  @click="viewStructure(scope.row)">查看</span>
        </template>
      </el-table-column>
      
      <el-table-column label="操作" min-width="190" align="center">
        <template slot-scope="scope">
          <span class="btn" @click="toModify(scope.row)">修改信息</span>
          <span class="btn" @click="viewAssess(20,1,scope.row)" v-if="scope.row.exam_count">评估详情</span>
          <el-tooltip v-else class="gray" content="该成员未参与评估" placement="top" :open-delay="400">
            <el-button>评估详情</el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <pagination ref="memberPage" @change="getMember" :total="memberTotal"></pagination>
      
    <!-- 邀请新成员弹窗 -->
    <el-dialog
      title="邀请成员"
      :visible.sync="inviteShow"
      :close-on-click-modal="false"
      width="560px"
      @close="closeDialog"
      center>
      <div class="dialog-input-body" style="height:302px">
        <div class="top-nav">
          <span :class="{current:inviteType==0}" @click="toggle(0)">手机号邀请</span>
          <span :class="{current:inviteType==1}" @click="toggle(1)">小程序码邀请</span>
        </div>

        <template v-if="inviteType==0">
          <div class="input-row">
            <h4>姓名</h4>
            <div class="box" :class="{error:errorType==1}">
              <input 
                class="input"
                placeholder='请输入真实姓名' 
                maxlength="16"
                v-model="inviteName"
                @input="setError"
                ref="inviteName">
              <div class="input-icon icon-clear" title="清空" v-show="inviteName" @click="inviteName='';errorType=0"></div>
              <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
            </div>
          </div>
          <div class="input-row">
            <h4>手机号</h4>
            <div class="box" :class="{error:errorType==2}">
              <el-select v-model="country_code" @change="selectCountry" class="el-select-code">
                <el-option
                  v-for="(item,code) in $country"
                  :key="code"
                  :label="item.label"
                  :value="code">
                </el-option>
              </el-select>
              <input 
                class="input"
                style="padding-top:2px"
                placeholder='请输入手机号'
                maxlength="11"
                v-model="phone"
                @input="inputChange($event,'phone')"
                ref="phone">
              <div class="input-icon icon-clear" title="清空" v-show="phone" @click="phone='';errorType=0"></div>
              <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
            </div>
          </div>
          <p class="tips">提示：当前可邀请人数最大上限为{{maxInviteNum}}</p>
          <div class="bottom-btn" @click="mobileInvite" v-show="btnLoadType==0">确认</div>
          <div class="bottom-btn loading-dot" v-show="btnLoadType==1">添加中<i>.</i><i>.</i><i>.</i></div>
        </template>
        <template v-else-if="inviteType==1">
          <div class="input-row" style="margin:60px 0">
            <h4>数量</h4>
            <div class="box" :class="{error:errorType==3}">
              <input 
                class="input"
                placeholder='请输入数量' 
                maxlength="6"
                @input="inputChange($event,'sendNumber')"
                v-model="sendNumber">
              <div class="input-icon icon-clear" title="清空" v-show="sendNumber" @click="sendNumber='';errorType=0"></div>
              <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
            </div>
          </div>
          <p class="tips">提示：当前可邀请人数最大上限为{{maxInviteNum}}</p>
          <div class="bottom-btn loading-dot" v-if="creating">生成中<i>.</i><i>.</i><i>.</i></div>
          <div class="bottom-btn" @click="createQrcode" v-else>生成小程序码</div>
        </template>
        <template v-else>
          <div class="qrcode">
            <img :src="qrcode">
            <div class="downloadBtn" style="opacity:.8;cursor:not-allowed;" v-if="qrcodeDownload">已下载</div>
            <div @click="download" class="downloadBtn" v-else>下 载</div>
            <p>提示：被邀请人可通过扫描此小程序码进入评估，可在<router-link to="/tvr/invite">邀请管理</router-link>中查看邀请详情或结束此邀请</p>
          </div>
        </template>
      </div>
    </el-dialog>
    <!-- 修改信息弹窗 -->
    <el-dialog
      title="修改信息"
      :visible.sync="modifyShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row">
          <h4>姓名</h4>
          <div class="box gray">{{memberNow.realname}}</div>
        </div>
        <div class="input-row">
          <h4>手机号</h4>
          <div class="box gray">{{$mobileToStar(memberNow.mobile||'')}}</div>
        </div>
        <div class="input-row">
          <h4 class="text-right">职位层级</h4>
          <div class="box" :class="{error:errorType==5}">
            <el-select v-model="tierId" placeholder="请选择职位层级" class="select-init" @change="setError()">
              <el-option
                v-for="item in tier"
                :key="item.name"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <div class="errText" v-show="errorType==5">{{errorMsg}}</div>
          </div>
        </div>
        <div class="input-row">
          <h4 class="text-right">职业类型</h4>
          <div class="box" :class="{error:errorType==6}">
            <el-select v-model="jobId" placeholder="请选择职业类型" class="select-init" @change="setError()">
              <el-option v-for="item in jobList" :key="item.type" :label="item.type" :value="item.id">
              </el-option>
            </el-select>
            <div class="errText" v-show="errorType==6">{{errorMsg}}</div>
          </div>
        </div>

        <div class="bottom-btn" @click="modify">确 认</div>
      </div>
    </el-dialog>
    <!-- 查看成员所属架构弹窗 -->
    <el-dialog
      title="所属架构"
      class="dialog-middle"
      :visible.sync="structureShow"
      :close-on-click-modal="false"
      center>
      <el-table
        :data="structure"
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column label="姓名" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-head" style="width:100px;margin:0 auto">
              <img :src="scope.row.avatar || require('../../../assets/images/head.png')">
              <span>{{scope.row.realname}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="手机号" align="center">
          <template slot-scope="scope">
            <span>{{$mobileToStar(scope.row.mobile)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="structure_name" label="所属架构" align="center"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 查看成员评估记录弹窗 -->
    <el-dialog
      :title="memberNow.realname+' 评估记录'"
      class="dialog-middle"
      :visible.sync="assessShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      center>
      <el-table
        v-loading="assessLoading"
        :data="assessList"
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column label="邀请人" min-width="100" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-head" style="width:102px;margin:0 auto">
              <img :src="scope.row.invite_avatar || require('../../../assets/images/head.png')">
              <span>{{scope.row.invite_realname}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="加入时间" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.create_time,'y-m-d h:m')}}</span>
          </template>
        </el-table-column>
        
        <el-table-column label="评估状态" min-width="70" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status==2" class="blue">已完成</span>
            <span v-else class="red">未完成</span>
          </template>
        </el-table-column>
        
        <el-table-column label="完成时间" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.end_time,'y-m-d h:m') || '-'}}</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="assessList.length>2" ref="assessPage" @change="viewAssess" :total="assessTotal" :position="'page-botttom'"></pagination>
    </el-dialog>
    <!-- 导入成员 -->
    <el-dialog
      title="导入成员"
      class="dialog-middle"
      :visible.sync="uploadShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="560px"
      center>
      <div class="explain">
        <p>导入方法：</p>
        <p>1.请先点击下载<span v-if="downloaded">excel模板文件</span><a target="_blank" :href="$baseRoot+'/Employee/downTemplate'" @click="downloadExcel" v-else>excel模板文件</a>（模板文件格式请勿修改，否则导致上传失败）</p>
        <p>2.按excel模板文件内容编辑成员信息，保存后上传该文件</p>
        <p>3.上传成功后点击确认激活后即可成功导入成员</p>
      </div>
      <div class="upload-box flex--cen">
        选择文件
        <el-upload
          ref="upload"
          action=""
          class="upload"
          :limit="1"
          :on-exceed="uploadExceed"
          :before-upload="beforeUpload"
          :http-request="uploadPost"
          drag>
          <div class="el-upload__text">点击选择excel文件，或将文件拖到此处</div>
        </el-upload>
        <div class="uploadFailText" v-show="uploadType==1">{{uploadFailText}}</div>
      </div>
      <div class="bottom-btn" v-if="uploadType==2" @click="upload">上传文件</div>
      <div class="bottom-btn" style="opacity:.7" v-else>上传文件</div>
    </el-dialog>
    <!-- 导入错误成员列表 -->
    <el-dialog
      title="导入错误成员列表"
      class="dialog-middle"
      :visible.sync="uploadFailShow"
      :close-on-click-modal="false"
      width="560px"
      center>
      <el-table
        :data="failList"
        tooltip-effect="dark">
        <el-table-column prop="line_number" label="错误行数" align="center" min-width="60"></el-table-column>
        <el-table-column prop="realname" label="姓名" align="center" min-width="90"></el-table-column>
        <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
        <el-table-column prop="reason" label="错误信息" align="center" min-width="160"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 上传检验成功 -->
    <el-dialog
      title="上传成功"
      class="el-dialog-confirm"
      :visible.sync="activeShow"
      :close-on-click-modal="false"
      center>
      <span>已成功录入{{activeTotal}}人信息，确认激活后将扣除对应评估数量，是否立即激活评估？</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="active">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../../assets/part/page'
export default {
  components: {pagination,},
  props: ['tier'],
  data(){
    return {
      reg: this.$CNReg,         // 当前区号的手机号正则
      country_code: '86',       // 区号
      phone: '',                // 手机号码
      code: '',                 // 验证码
      errorMsg: '',             // 输入框错误信息
      errorType: 0,             // 1：姓名为空，2：手机号错误，3：邀请数错误

      maxInviteNum: 0,
      btnLoadType: 0,
      inviteName: '',
      inviteShow: false,
      inviteType: 0,            // 
      sendNumber: '',           // 发出邀请数
      creating: false,
      qrcode: '',
      qrcodeDownload: false,    // 是否已经下载了小程序码

      modifyShow: false,
      tierId: '',
      jobId: '',
      jobList: [],

      memberList: [],
      memberTotal: 0,
      memberLoading: false,
      memberNow: {},
      keyword: '',

      structure: [],
      structureShow: false,

      assessShow: false,
      assessList: [],
      assessTotal: [],
      assessLoading: false,

      uploadType: 0,        //上传状态（0：初始化，1：选择文件有误，2：可上传状态，3：已上传）
      downloaded: sessionStorage.downloaded || '',
      uploadShow: false,
      uploadFailShow: false,
      uploadFailText: '',
      failList: [],
      activeShow: false,
      activeTotal: 0,
    }
  },
  mounted(){
    this.getMember(20, 1)

    this.$request({url:'/Index/getType',
      success: ({data}) => this.jobList = data || []
    })
    
    this.getMaxInviteNum()
  },
  methods:{
    getMember(per_page, page){
      this.memberLoading = true
      let ref = this.$refs.memberPage;
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$request({
        url:'/Tvs/employeeIndex',
        data: {
          keyword: this.keyword,
          per_page: per_page,
          page: page,
        },
        success: (data) => {
          if (data.code == 0) {
            this.memberTotal = data.data.total
            this.memberList = data.data.data
          } else if (data.code == 101) {
            this.$router.replace({path:'/login',query:{back:this.$route.path}})
            localStorage.clear();
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
        complete: () => this.memberLoading = false
      })
    },
    searchMember(e){
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(()=>{
        this.keyword = e.target.value
        this.getMember(null, 1)
        this.$refs.memberPage.page = 1
      }, 600)
    },
    viewStructure(row){
      this.$request({
        url:'/Tvs/getEmployeeOrganization',
        data: {user_id: row.user_id},
        success: (data) => {
          if (data.code == 0) {
            this.structure = data.data
            this.structureShow = true
          } else {
            this.$message({duration: 2000,message: data.msg})
          }
        },
        complete: () => this.memberLoading = false
      })
    },
    viewAssess(per_page, page, row){
      this.assessLoading = true
      let ref = this.$refs.assessPage
      per_page = ref ? ref.per_page : per_page || 20
      page = ref ? ref.page : page || 1
      this.$request({
        url:'/Tvs/getEmployeeExam',
        data: {
          user_id: row ? row.user_id : this.memberNow.user_id,
          per_page: per_page,
          page: page,
        },
        success: (data) => {
          if (data.code == 0) {
            this.assessList = data.data.data
            this.assessTotal = data.data.total
            this.assessShow = true
            this.memberNow = row || this.memberNow
          } else {
            this.$message({duration: 2000,message: data.msg})
          }
        },
        complete: () => this.assessLoading = false
      })
    },
    getMaxInviteNum(){
      this.$request({url: '/index/getTvsStatistics',
        success: ({code, data}) => {
          if (code == 0) {
            this.maxInviteNum = data.may_invite_num
          }
        }
      })
    },
    // 点击修成员改信息
    toModify(row){
      this.modifyShow = true
      this.memberNow = row
      this.tierId = row.tier_id || null
      this.jobId = row.type_id || null
    },
    // 修改成员信息
    modify(){
      if (!this.tierId) {
        this.setError(5,'请先选择职位层级')
        return
      } else if(!this.jobId){
        this.setError(6,'请先选择职业类型')
        return
      }
      this.$request({
        url:'/Tvs/updateEmployeeInfo',
        data: {
          employee_id: this.memberNow.employee_id,
          tier_id: this.tierId,
          type_id: this.jobId,
        },
        success: (data) => {
          if (data.code == 0) {
            this.modifyShow = false
            this.getMember()
            this.$message({message: '修改成功', type: 'success'})
          } else {
            this.$message({message: data.msg, type: 'error'})
          }
        },
      })
    },
    //手机号邀请新成员
    mobileInvite(){
      if (!this.inviteName) {
        this.setError(1,'请先输入姓名')
        this.$refs.inviteName.focus()
        return
      } else if (!this.phone) {
        this.setError(2,'请先输入手机号')
        this.$refs.phone.focus()
        return
      } else if (!this.reg.test(this.phone)) {
        this.setError(2,'手机号码格式不对')
        this.$refs.phone.focus()
        return
      }
      this.btnLoadType = 1
      this.$request({url: '/Tvs/getTopTeamId',
        success: e =>{
          if (e.code == 0 ){
            this.$request({
              url: '/Tvs/baseMobileInvite',
              data: {
                team_id: e.data.top_team_id,
                realname: this.inviteName,
                mobile: this.phone,
                country_code: this.country_code,
              },
              success: data => {
                if (!data.code) {
                  this.$message({message: '邀请成功', type:'success'})
                  this.inviteShow = false
                  this.getMember()
                  this.maxInviteNum -= 1
                } else {
                  this.$message({message:data.msg, type:'error'})
                }
              },
              complete: this.btnLoadType = 0
            })
          } else {
            this.btnLoadType = 0
            this.$message({duration:3000, message:e.msg, type:'error'})
          }
        },
        error: (err)=>{
          this.btnLoadType = 0
          this.$message({duration:2000, message:'网络错误', type:'error'})
        }
      })
    },
    //小程序码邀请
    createQrcode(){
      if (!this.sendNumber) {
        this.setError(3, '请输入邀请数')
        return
      } else if (this.sendNumber > this.maxInviteNum) {
        this.setError(3, '数量超出可邀请数')
        return
      }
      this.creating = true
      this.$request({
        url:'/Tvs/codeInvite',
        data: {invite_num: this.sendNumber},
        success: (data) => {
          if (data.code == 0) {
            this.getMaxInviteNum()
            this.$request({
              url: this.$planetUrl+'/Common/createQrcode',
              data: {
                param: data.data.id + '=' + data.data.code,
                page: 'pages/TVR/inviteAccept/inviteAccept',
                width: 280,
              },
              success: (e) => {
                if (e.code == 0) {
                  this.inviteType = 2
                  this.qrcode = e.data.qrcode_path
                } else {
                  this.$message({duration: 2000, message: e.msg})
                }
              },
              complete: ()=>{
                this.creating = false
              }
            })
          } else {
            this.creating = false
            this.$message({duration: 2000, message: data.msg})
          }
        },
        error: ()=>{
          this.creating = false
          this.$message.error('网络错误，请稍后再试')
        }
      })
    },
    download(){
      if (this.qrcodeDownload == false) {
        window.open(`${this.$baseRoot}/CompanyInvite/downQrcode?qrcode_path=${this.qrcode}`)
        this.qrcodeDownload = true
      }
    },
    // 选择区号筛选手机号正则
    selectCountry(key){
      this.$countryList.forEach((item)=>{
        if(item.country_code==key){
          this.reg = this[item.key]
          return
        }
      })
    },
    // 监听输入内容
    inputChange(e, key = 'phone'){
      let text = e.target.value
      this[key] = text.replace(/\D/g,'')
      this.errorType = 0;

      if(key == 'sendNumber' && text.charAt(0) == '0')
        this.sendNumber = text.substring(1, text.length);
    },
    setError(type = 0, msg = ''){
      this.errorType = type
      this.errorMsg = msg
    },
    closeDialog(){
      this.inviteName = ''
      this.phone = ''
      this.sendNumber = null
      this.memberNow = {}
      this.tierId = ''
      this.jobId = ''
      this.btnLoadType = 0
      this.setError()
      this.setUploadError()
      this.qrcodeDownload = false
      this.inviteType = 0
    },
    // 切换 短信登录/账号密码登录
    toggle(type){
      if (this.inviteType==type)  return;
      else if (this.inviteType==2 && type==1) return
      this.inviteType = type
      this.errorType = 0
      this.inviteName = ''
      this.phone = ''
      this.sendNumber = null
    },
    // 下载excel模板文件
    downloadExcel(){
      sessionStorage.downloaded = this.downloaded = 1
    },
    // 上传前检验
    beforeUpload(file){
      let fileType = file.name.split('.')[1]
      if (file.size / 1024 / 1024 > 20) { //最大20m
        this.setUploadError(1, '上传文件过大，请重新选择')
        return false
      } else if (fileType!=='xls' && fileType!=='xlsx') {
        this.setUploadError(1, '只能上传excel文件，请重新选择')
        return false;
      }
      return true
    },
    setUploadError(type = 0, text = ''){
      this.uploadType = type
      this.uploadFailText = text
    },
    uploadExceed(e){
      this.$message.warning('每次只能上传1个文件')
    },
    upload(){
      if (this.uploadType==1) {
        return;
      } else if (this.uploadType==2) {
        this.$request({
          url:'/Tvs/checkEmployeeBatch',
          data: {batch_id: this.batch_id},
          success: e => {
            if (e.code == 0) {
              this.activeShow = true
              this.uploadShow = false
              this.activeTotal = e.data.success_data.length
            } else if (e.code == 6) {
              this.$message.error('请检查上传文件');
              this.failList = e.data.error_data
              this.uploadFailShow = true
            } else if (e.code == 5) {
              this.setUploadError(1,'可用数量不足，最大上限为'+this.maxInviteNum+'，请重新上传')
            } else {
              this.$message.error('上传失败');
            }
            this.uploadType = 0
            this.$refs.upload.clearFiles()
          },
          error: ()=>{
            this.$message.error('导入失败,请重试');
          }
        })
      }
    },
    uploadPost({file}){
      this.setUploadError()
      let formData = new FormData()
      formData.append('batch_file',file)
      //上传文件到服务器
      this.$request({
        headers: {'Content-Type': 'multipart/form-data'},
        method: 'post',
        url:'/Tvs/uploadBatch',
        data: formData,
        success: data => {
          if (data.code == 0) {
            this.batch_id = data.data.batch_id
            this.uploadType = 2
          } else {
            this.batch_id = ''
            this.uploadType = 1
            this.uploadFailText = '文件有误,请重新选择'
            this.$refs.upload.clearFiles()
          }
        },
        error: () => {
          this.uploadType = 1
          this.uploadFailText = '文件有误,请重新选择'
          this.$refs.upload.clearFiles()
        }
      })
    },
    active(){
      this.$request({
        url:'/Tvs/activeEmployeeBatchInfo',
        data: {batch_id:this.batch_id},
        success: data => {
          if (data.code == 0) {
            this.activeShow = false
            this.$message.success('导入成功');
            this.getMaxInviteNum()
            this.getMember(null,1)
          } else if (data.code == 8) {
            this.$message.error(data.msg)
          } else {
            this.$message.error(data.msg)
          }
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  .staff{
    min-width: 480px;
    .qrcode{
      img{
        width: 136px;
        height: 136px;
        display: block;
        margin: -10px auto 0;
      }
      .downloadBtn{
        border-radius: 4px;
        width: 100px;
        color: #fff;
        background: #6b6be5;
        text-align: center;
        line-height: 30px;
        height: 30px;
        margin: 20px auto;
        cursor: pointer;
      }
      p{
        color: #868686;
        a{color: #6b6be5;cursor: pointer;}
      }
    }
    .explain{
      color: #868686;
      margin: 40px 50px;
      line-height: 1.6;
      a{
        color: #6b6be5;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .bottom-btn{margin-bottom: 0}
    .upload-box{
      margin: 44px 50px 52px;
      position: relative;
      .upload{
        flex: 1;
        margin-left: 10px;
      }
    }
  }
</style>