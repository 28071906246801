<template>
  <div class="index">
    <h1 class="h1_title">
      <img src="../../assets/images/company/nav_index.png">控制台首页
    </h1>
    <div class="move-up"></div>
    <div class="content flex">

      <div style="flex:1">
        <h2 class="flex--cen"><i></i>详情统计</h2>
        <ul>
          <li @click="toProduct('tvs')">
            <div class="pic flex--cen">
              <img src="../../assets/images/company/product5.png">
              组织活力扫描
            </div>
            <div class="flex-bet">
              <div class="number"><b>{{tvs.total_number}}</b><span>购买总数</span></div>
              <div class="number"><b>{{tvs.may_invite_num}}</b><span>可使用数</span></div>
              <div class="number"><b>{{tvs.complete_num}}</b><span>已完成数</span></div>
            </div>
          </li>
          <li class="li1" @click="toProduct('tvr')">
            <div class="pic flex--cen">
              <img src="../../assets/images/company/product1.png">
              TVR团队活力识别器
            </div>
            <div class="flex-bet">
              <div class="number"><b>{{tvr.total_number}}</b><span>购买总数</span></div>
              <div class="number"><b>{{tvr.may_invite_num}}</b><span>可使用数</span></div>
              <div class="number"><b>{{tvr.complete_num}}</b><span>已完成数</span></div>
            </div>
          </li>
          <li class="li2" @click="toProduct('lsn')">
            <div class="pic flex--cen">
              <img src="../../assets/images/company/product4.png">
              LSN精益创业导航
            </div>
            <div class="flex-bet">
              <div class="number"><b>{{lsn.total_number}}</b><span>购买总数</span></div>
              <div class="number"><b>{{lsn.may_invite_num}}</b><span>可使用数</span></div>
              <div class="number"><b>{{lsn.complete_num}}</b><span>已完成数</span></div>
            </div>
          </li>
          <li class="li3" @click="toProduct('amt')">
            <div class="pic flex--cen">
              <img src="../../assets/images/company/product2.png">
              AMT领导者画像
            </div>
            <div class="flex-bet">
              <div class="number"><b>{{amt.total_number}}</b><span>购买总数</span></div>
              <div class="number" v-if="amt.total_number>0"><b>{{amt.may_invite_num}}</b><span>可使用数</span></div>
              <div class="number"><b>{{amt.complete_num}}</b><span>已完成数</span></div>
            </div>
          </li>
          <li class="li4" @click="toProduct('cbc')">
            <div class="pic flex--cen">
              <img src="../../assets/images/company/product3.png">
              职涯优选CBC
            </div>
            <div class="flex-bet">
              <div class="number"><b>{{cbc.total_number}}</b><span>购买总数</span></div>
              <div class="number"><b>{{cbc.may_invite_num}}</b><span>可使用数</span></div>
              <div class="number"><b>{{cbc.complete_num}}</b><span>已完成数</span></div>
            </div>
          </li>
          <li class="li5" @click="toProduct('cognition')">
            <div class="pic flex--cen">
              <img src="../../assets/images/company/product6.png">
              认知差异分析
            </div>
            <div class="flex-bet">
              <div class="number"><b>{{cognition.total_number}}</b><span>购买总数</span></div>
              <div class="number"><b>{{cognition.may_invite_num}}</b><span>可使用数</span></div>
              <div class="number"><b>{{cognition.complete_num}}</b><span>已完成数</span></div>
            </div>
          </li>
        </ul>
      </div>

      <div class="right">
        <h2 class="flex--cen"><i></i>日历卡</h2>
        <img class="card" :src="card">
        <h2 class="flex--cen"><i></i>公告栏</h2>
        <div class="notice">
          <p v-for="item in notice" :key="item.date">{{item.title}}</p>
        </div>
        <div class="service">
          <img :src="service.qrcde_image || require('../../assets/images/code-planet.jpg')">
            <p>专属客服：{{service.realname}}</p>
            <p>客服热线：{{service.mobile}}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      tvs: {total_number:0,may_invite_num:0,complete_num:0},
      tvr: {total_number:0,may_invite_num:0,complete_num:0},
      lsn: {total_number:0,may_invite_num:0,complete_num:0},
      amt: {total_number:0,may_invite_num:0,complete_num:0},
      cbc: {total_number:0,may_invite_num:0,complete_num:0},
      cognition: {total_number:0,may_invite_num:0,complete_num:0},
      card: '',
      notice: [],
      service: {},
    }
  },
  created(){
    this.$request({
      url: '/index/index',
      success: (e) => {
        if (e.code == 0) {
          let data =  e.data
          this.card = data.daily_card
          this.notice = data.notice
          this.service = data.customer_service
          if (data.data_statistics.tvs)
            this.tvs = data.data_statistics.tvs
          if (data.data_statistics.tvr)
            this.tvr = data.data_statistics.tvr
          if (data.data_statistics.amt)
            this.amt = data.data_statistics.amt
          if (data.data_statistics.cbc)
            this.cbc = data.data_statistics.cbc
          if (data.data_statistics.lsn)
            this.lsn = data.data_statistics.lsn
          if (data.data_statistics.cd)
            this.cognition = data.data_statistics.cd
        } else if (e.code == 101) {
          this.$router.replace({path:'/login',query:{back:this.$route.path}})
          localStorage.clear();
        } else if (e.code == 102) {
          this.$router.replace({path:'/userCenter'})
        } else {
          this.$message({duration: 2000,message: e.msg,type:'error'})
        }
      },
    })
  },
  methods: {
    toProduct(path){
      if (!this[path].is_auth) {
        this.$message({message: '未授权，请联系管理员',duration: 2000,type:'warning'})
      } else if (this[path].total_number == 0) {
        this.$message({message: '请先购买服务',duration: 2000,type:'warning'})
      } else {
        this.$router.push({path: path})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .index{
    height:calc(100vh - 50px);
    padding-bottom: 0;
    padding-right: 0;
    h2{
      font-size: 15px;
      color: #2b2b2b;
      margin: 25px 0 20px;
      i{
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #6b6be5;
        margin: 0 6px 0 4px;
      }
    }
    .content{
      align-items: stretch;
      min-height: calc(100vh - 120px);
      ul{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        padding-right: 20px;
        li{
          white-space: nowrap;
          box-shadow: 0 0 2px #ccc;
          padding: 24px 20px 30px;
          flex-shrink: 1;
          border-radius: 4px;
          border-bottom: 3px solid #2581be;
          cursor: pointer;
          .pic{
            font-size: 14px;
            color: #545454;
            margin-bottom: 30px;
            img{width: 40px;margin-right: 10px;}
          }
          .number{
            display: flex;
            flex-direction: column;
            align-items: center;
            b{
              font-size: 22px;
              margin-bottom: 10px;
              color: #2581be;
            }
            span{
              font-size: 12px;
            }
          }
        }
        .li1{
          border-color: #6b6be5;
          .number b{color: #6b6be5;}
        }
        .li2{
          border-color: #b459df;
          .number b{color: #b459df;}
        }
        .li3{
          border-color: #e76f4a;
          .number b{color: #e76f4a;}
        }
        .li4{
          border-color: #4cc1de;
          .number b{color: #4cc1de;}
        }
        .li5{
          border-color: #d353ca;
          .number b{color: #d353ca;}
        }
      }
      .right{
        border-left: 1px solid #eceef3;
        width: 300px;
        padding: 0 10px;
        flex-shrink: 0;
        padding-top: 1px;
        display: flex;
        flex-direction: column;
        &::-webkit-scrollbar{display: none}
        .card{
          width: 82%;
          display: block;
          margin: 0 auto 10px;
          min-height: 150px;
          flex-shrink: 0;
          background: #ccc;
        }
        .notice{
          font-size: 12px;
        }
        .service{
          margin: 60px 5px 20px;
          min-height: 138px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: column;
            color: #2b2b2b;
          img{
            width: 100px;
            margin-right: 4px;
            flex-shrink: 0;
          }
          p{font-size: 12px;}
          .name{
            font-size: 14px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
  
  @media (max-width: 1340px) {
    .index .content{
      ul{
        li{
          padding: 20px 16px 20px;
          .pic{margin-bottom: 20px}
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .index .content{
      .right{
        width: 180px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 1000px) {
    .index .content{
      ul{grid-template-columns: 1fr 1fr;}
    }
  }
</style>