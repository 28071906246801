<template>
  <div class="structure">
    <h1 class="h1_title">
      <img src="../../../assets/images/company/nav_structure.png">架构管理
    </h1>
    <div class="move-up"></div>
    <div class="table-btn">
      <input type="text" placeholder="搜索团队" class="search-input" v-model="searchName" @input="search">
      <b style="flex:1"></b>
      <div class="btn" v-if="structureTotal<3" @click="structureShow=true">添加架构</div>
      <el-tooltip v-else class="btn btn-disable" content="架构数已达上限" placement="top" :open-delay="300">
        <el-button>添加架构</el-button>
      </el-tooltip>
    </div>
    <h5 class="flex--cen">
      <img src="../../../assets/images/company/icon-company.png">
      {{companyName}}
    </h5>
    <div class="tree">
      <!-- 架构一 -->
      <h6 @click="open1=!open1" :class="{rotate:open1,flash:addLevel==1}" v-if="level_1">
        <span>{{level_1.structure_name}}</span>
        <span class="button not">-</span>
        <span class="button" @click.stop="changeConfirm(1, level_1)">修改名称</span>
      </h6>
      <div class="box" v-if="level_1" :style="{height: open1 ? (`${level_1.team.length ? level_1.team.length*40 : 40}px`) : 0}">
        <p v-for="(item) in level_1.team" :key="item.id" :class="{flashKeep:searchName && item.organization_name.indexOf(searchName)>-1}">
          {{item.organization_name}}
          <span class="button red" @click.stop="delConfirm(2,item)">解散团队</span>
          <span class="button" @click.stop="changeConfirm(2, item)">修改名称</span>
        </p>
        <p class="none" v-show="level_1.team.length==0">暂无团队</p>
      </div>
      <!-- 架构二 -->
      <h6 @click="open2=!open2" :class="{rotate:open2,flash:addLevel==2}" v-if="level_2" style="margin-left:30px">
        <span>{{level_2.structure_name}}</span>
        <span class="button not" v-if="level_3 || level_2.team.length">-</span>
        <span class="button red" @click.stop="delConfirm(1,level_2)" v-else>移除架构</span>
        <span class="button" @click.stop="changeConfirm(1, level_2)">修改名称</span>
      </h6>
      <div class="box" v-if="level_2" :style="{height: open2 ? (`${level_2.team.length ? level_2.team.length*40 : 40}px`) : 0}" style="margin-left:60px">
        <p v-for="(item) in level_2.team" :key="item.id" :class="{flashKeep:searchName && item.organization_name.indexOf(searchName)>-1}">
          {{item.organization_name}}
          <span class="button red" @click.stop="delConfirm(2,item)">解散团队</span>
          <span class="button" @click.stop="changeConfirm(2, item)">修改名称</span>
        </p>
        <p class="none" v-show="level_2.team.length==0">暂无团队</p>
      </div>
      <!-- 架构三 -->
      <h6 @click="open3=!open3" :class="{rotate:open3,flash:addLevel==3}" v-if="level_3" style="margin-left:60px">
        <span>{{level_3.structure_name}}</span>
        <span class="button not" v-if="level_3.team.length">-</span>
        <span class="button red" @click.stop="delConfirm(1,level_3)" v-else>移除架构</span>
        <span class="button" @click.stop="changeConfirm(1, level_3)">修改名称</span>
      </h6>
      <div class="box" v-if="level_3" :style="{height: open3 ? (`${level_3.team.length ? level_3.team.length*40 : 40}px`) : 0}" style="margin-left:90px">
        <p v-for="(item) in level_3.team" :key="item.id"  :class="{flashKeep:searchName && item.organization_name.indexOf(searchName)>-1}">
          {{item.organization_name}}
          <span class="button red" @click.stop="delConfirm(2,item)">解散团队</span>
          <span class="button" @click.stop="changeConfirm(2, item)">修改名称</span>
        </p>
        <p class="none" v-show="level_3.team.length==0">暂无团队</p>
      </div>
    </div>
    <!-- 添加架构弹窗 -->
    <el-dialog
      title="添加架构"
      :visible.sync="structureShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row">
          <h4 class="must">架构名</h4>
          <div class="box" :class="{error:errorType==1}">
            <input 
              @input="errorType=0"
              class="input"
              maxlength="30"
              v-model="newStructureName"
              ref="name">
            <div class="input-icon icon-clear" title="清空" v-show="newStructureName" @click="newStructureName='';errorType=0"></div>
            <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
          </div>
        </div>
        <p class="tips">提示：企业下最多可建立三层(级)架构</p>
        <div style="margin-top:80px" class="bottom-btn" @click="addStructure">确 认</div>
      </div>
    </el-dialog>
    <!-- 解散确认框 -->
    <el-dialog
      :title="pickType==1?'移除架构':'解散团队'"
      class="el-dialog-confirm"
      :visible.sync="delShow"
      :close-on-click-modal="false"
      center>
      <span v-if="pickType==1&&pickNow.team.length">{{pickType==1?'移除':'解散'}}后，此架构下所有团队将移至上级架构，确认要解散 [<b>{{delName}}</b>]？</span>
      <span v-else>确认{{pickType==1?'移除':'解散'}}[<b>{{delName}}</b>]？</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="delSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改名称弹窗 -->
    <el-dialog
      title="修改名称"
      :visible.sync="changeShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row" style="margin:60px 0">
          <h4 class="must">新名称</h4>
          <div class="box" :class="{error:errorType==2}">
            <input 
              class="input"
              maxlength="24"
              @input="checkName"
              v-model="changeName"
              ref="name">
            <div class="input-icon icon-clear" title="清空" v-show="changeName" @click="changeName='';errorType=0"></div>
            <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
          </div>
        </div>
        <div style="margin-top:70px" class="bottom-btn" @click="changeSubmit">确 认</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ['structureTotal'],
  data(){
    return {
      loading: false,
      companyName: '',
      errorType: 0,
      errorMsg: '',

      pickType: 0,                // 1：点击 架构 操作，2：点击 团队 操作
      pickNow: '',                // 选中的团队或架构
      changeShow: false,
      changeName: '',             // 新修改的名称
      delShow: false,
      delName: '',

      addLevel: 0,
      level_1: null,
      level_2: null,
      level_3: null,
      open1: false,
      open2: false,
      open3: false,
      searchName: '',
      
      structureShow: false,       // 添加架构显隐
      newStructureName: '',       // 新添加的架构名
    }
  },
  created(){
    this.getStructure()
  },
  methods: {
    getStructure(){
      this.$request({url:'/CompanyStructure/index',
        success: (data) => {
          if (data.code == 0) {
            let structure = data.data.company_structure
            this.level_1 = structure[0] || null
            this.level_2 = structure[1] || null
            this.level_3 = structure[2] || null
            this.companyName = data.data.company_info.companyname
          } else if (data.code == 101) {
            this.$router.replace({path:'/login',query:{back:this.$route.path}})
            localStorage.clear();
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
      })
    },
    addStructure(){
      if(!this.newStructureName){
        this.errorType = 1
        this.errorMsg = '请先输入架构名'
        return
      }
      this.$request({url:'/CompanyStructure/addStructure',
        data: {structure_name: this.newStructureName},
        success: (data) => {
          if (data.code == 0) {
            this.structureShow = false
            this.getStructure()
            this.$parent.getStructure()
            this.$message({duration: 2000,message:'添加成功',type:'success'})
            this.addLevel = data.data.level
            setTimeout(()=>{this.addLevel = 0;},4000)
          } else if (data.code == 3) {
            this.errorType = 1
            this.errorMsg = '架构已存在，请输入新的架构名'
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
      })
    },
    search(e){
      let level = ['',this.level_1, this.level_2, this.level_3]
      for(let i = 1; i < 4; i++){
        if (level[i] && e.target.value) {
          level[i].team.forEach( item => {
            if (item.organization_name.indexOf(e.target.value) > -1) {
                this['open'+i] = true
            }
          });
        }
      }
    },
    // 修改名称输入框
    changeConfirm(type,now){
      this.pickType = type
      this.pickNow = now
      this.changeShow = true
      this.changeName = now.structure_name || now.organization_name
    },
    checkName(e){
      if (e.target.selectionEnd > 31) {
        this.errorType = 2
        this.errorMsg = '名称过长'
      } else {
        this.errorType = 0
      }
    },
    // 修改名称提交
    changeSubmit(){
      if (this.errorType) {
        return
      } else if(!this.changeName){
        this.errorType = 2
        this.errorMsg = '请先输入名称'
      } else if (this.pickType == 1) {
        this.$request({
          url:'/CompanyStructure/updateStructure',
          data: {
            structure_name: this.changeName,
            structure_id: this.pickNow.structure_id
          },
          success: (data) => {
            if (data.code == 0) {
              this.changeShow = false
              this.getStructure()
              this.$parent.getStructure()
              this.$message({duration:2000,message:'修改成功',type:'success'})
            } else {
              this.$message({duration: 2000,message: data.msg})
            }
          },
        })
      } else if (this.pickType == 2) {
        this.$request({
          url: '/Team/updateTeam',
          data: {
            team_id: this.pickNow.id,
            team_name: this.changeName,
            structure_id: this.pickNow.structure_id,
          },
          success: data => {
            if (!data.code) {
              this.changeShow = false
              this.getStructure()
              // this.$message({message:'修改成功',type:'success'})
            } else {
              this.$message({duration:2000,message:data.msg,type:'error'})
            }
          },
        })
      }
    },
    // 删除操作确认框
    delConfirm(type,now){ 
      this.pickType = type
      this.pickNow = now
      this.delShow = true
      this.delName = now.structure_name || now.organization_name
    },
    delSubmit(){
      if (this.pickType == 1) {
        this.$request({
          url:'/CompanyStructure/removeStructure',
          data: {structure_id: this.pickNow.structure_id},
          success: (data) => {
            if (data.code == 0) {
              this.delShow = false
              this.getStructure()
              this.$parent.getStructure()
              // this.$message({duration:2000,message:'解散成功',type:'success'})
            } else {
              this.$message({duration: 2000,message: data.msg})
            }
          },
        })
      } else if (this.pickType == 2) {
        this.$request({
          url: '/Team/disbandTeam',
          data: {team_id: this.pickNow.id},
          success: data => {
            if (!data.code) {
              this.delShow = false
              this.getStructure()
              this.$message({message:'解散成功',type:'success'})
            } else {
              this.$message({duration:2000,message:data.msg,type:'error'})
            }
          },
        })
      }
    },
    closeDialog(){
      this.newStructureName = ''
      this.pickNow = {}
      this.pickType = 0
      this.errorType = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.structure{
  h5{
    color: #2b2b2b;
    img{
      width: 16px;
      height: 16px;
      margin-right: 5px;
      margin-bottom: 1px;
    }
  }
  .tree{
    user-select: none;
    color: #868686;
    line-height: 40px;
    font-size: 14px;
    margin-top: 20px;
    h6{
      border-bottom: 1px solid #EBEEF5;
      padding-left: 20px;
      color: #2b2b2b;
      font-weight: bold;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      &::before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: 6px solid #4d4d4d;
        left: 4px;
        top: 17px;
        transform: rotate(-90deg);
        transition: transform .2s;
      }
      &:hover{background: #f5f7fa;}
    }
    .rotate::before{transform: rotate(0)}
    .box{
      margin-left: 30px;
      height: 0px;
      overflow: hidden;
      transition: height .2s;
      transform-origin: center top;
      p{
        box-sizing: border-box;
        height: 40px;
        border-bottom: 1px solid #EBEEF5;
        padding-left: 16px;
        position: relative;
        &:hover{background: #f5f7fa;}
        &::before{
          content: '';
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          left: 5px;
          top: 50%;
          transform: translateY(-50%);
          background: #868686;
        }
      }
      .none{
        color: #ccc;
        &:hover{background: none;}
        &::before{display: none}
      }
    }
    .button{
      width: 64px;
      line-height: 40px;
      text-align: center;
      float: right;
      color: #6b6be5;
      font-size: 14px;
      font-weight: normal;
      margin: 0 15px 0 30px;
      &:hover{
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .not:hover{
      text-decoration: none;
      cursor: default;
    }
    .flash{animation: flash 3s;}
    .flashKeep{animation: flash 3s forwards;}
    @keyframes flash {
      0%{background: #fff}
      20%{background: #f0f0ff}
      40%{background: #fff}
      60%{background: #f0f0ff}
      80%{background: #fff}
      100%{background: #f0f0ff}
    }
  }
  .dialog-input-body{
    min-height: 180px;
  }
}
</style>