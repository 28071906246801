<template>
  <div class="staff">
    <h1 class="h1_title">
      <img src="../../../assets/images/company/nav_staff.png">员工管理
    </h1>
    <div class="move-up"></div>
    <div class="table-btn">
      <input type="text" placeholder="搜索" class="search-input" @input="searchMember">
      <b style="flex:1"></b>
      <div class="btn" @click="inviteShow=true" v-if="maxInviteNum">邀请成员</div>
      <el-tooltip v-else class="btn btn-disable" content="无可用数量" placement="top" :open-delay="300">
        <el-button>邀请成员</el-button>
      </el-tooltip>
    </div>
    <el-table
      v-loading="memberLoading"
      :data="memberList"
      tooltip-effect="dark"
      height="calc(100vh - 238px)"
      style="width: 100%">

      <el-table-column label="姓名" min-width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="table-head">
            <img :src="scope.row.avatar || require('../../../assets/images/head.png')">
            <span>{{scope.row.realname}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="性别" min-width="60" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.sex==2">女</span>
          <span v-else-if="scope.row.sex==1">男</span>
          <span v-else>未知</span>
        </template>
      </el-table-column>
      
      <el-table-column label="手机号" min-width="100">
        <template slot-scope="scope">
          <span>{{$mobileToStar(scope.row.mobile)}}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="年龄" min-width="60">
        <template slot-scope="scope">
          <span>{{scope.row.age}}</span>
        </template>
      </el-table-column>

      <el-table-column label="最新加入时间" min-width="130">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.join_time,'y-m-d h:m')||'-'}}</span>
        </template>
      </el-table-column>

      <el-table-column label="当前状态" min-width="90" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.exam_status==2" class="blue">已完成</span>
          <span v-else class="red">未完成</span>
        </template>
      </el-table-column>

      <el-table-column label="最新完成时间" min-width="140">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.complete_time,'y-m-d h:m')||'-'}}</span>
        </template>
      </el-table-column>

      <el-table-column label="领导思维类型" min-width="240" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.leader_ship">{{scope.row.leader_ship.join(',')}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column label="领导思维清晰度" width="120">
        <template slot-scope="scope">
          <span class="flex--cen" v-if="scope.row.definition">
            <i v-for="i in 5" :key="i" class="star" :class="{
              star1: i <= scope.row.definition, 
              star2: scope.row.definition < i && scope.row.definition > i-1
            }"></i>
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      
      <el-table-column label="操作" min-width="260" align="center">
        <template slot-scope="scope">
          <span class="btn" @click="preview(scope.row)" v-if="scope.row.exam_status==2">预览报告</span>
          <span class="btn-disable" v-else>预览报告</span>

          <span class="btn" @click="downloadReport(scope.row)" v-if="scope.row.exam_status==2">下载报告</span>
          <span class="btn-disable" v-else>下载报告</span>

          <span class="btn" @click="assessRecord(20,1,scope.row)">评估记录</span>
        </template>
      </el-table-column>
      
    </el-table>
    <pagination ref="memberPage" @change="getMember" :total="memberTotal"></pagination>
      
    <!-- 邀请新成员弹窗 -->
    <el-dialog
      title="邀请成员"
      :visible.sync="inviteShow"
      :close-on-click-modal="false"
      width="560px"
      @close="closeDialog"
      center>
      <div class="dialog-input-body" style="height:302px">
        <div class="top-nav">
          <span :class="{current:inviteType==0}" @click="toggle(0)">手机号邀请</span>
          <span :class="{current:inviteType==1}" @click="toggle(1)">小程序码邀请</span>
        </div>

        <template v-if="inviteType==0">
          <div class="input-row">
            <h4>姓名</h4>
            <div class="box" :class="{error:errorType==1}">
              <input 
                class="input"
                placeholder='请输入真实姓名' 
                maxlength="16"
                v-model="inviteName"
                @input="setError"
                ref="inviteName">
              <div class="input-icon icon-clear" title="清空" v-show="inviteName" @click="inviteName='';errorType=0"></div>
              <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
            </div>
          </div>
          <div class="input-row">
            <h4>手机号</h4>
            <div class="box" :class="{error:errorType==2}">
              <el-select v-model="country_code" @change="selectCountry" class="el-select-code">
                <el-option
                  v-for="(item,code) in $country"
                  :key="code"
                  :label="item.label"
                  :value="code">
                </el-option>
              </el-select>
              <input 
                class="input"
                style="padding-top:2px"
                placeholder='请输入手机号'
                maxlength="11"
                v-model="phone"
                @input="inputChange($event,'phone')"
                ref="phone">
              <div class="input-icon icon-clear" title="清空" v-show="phone" @click="phone='';errorType=0"></div>
              <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
            </div>
          </div>
          <p class="tips" v-if="service<2">提示：当前可邀请人数最大上限为{{maxInviteNum}}</p>
          <div class="bottom-btn" @click="mobileInvite" v-show="btnLoadType==0">确认</div>
          <div class="bottom-btn loading-dot" v-show="btnLoadType==1">添加中<i>.</i><i>.</i><i>.</i></div>
        </template>
        <template v-else-if="inviteType==1">
          <div class="input-row" style="margin:60px 0">
            <h4>数量</h4>
            <div class="box" :class="{error:errorType==3}">
              <input 
                class="input"
                placeholder='请输入数量' 
                maxlength="6"
                @input="inputChange($event,'sendNumber')"
                v-model="sendNumber">
              <div class="input-icon icon-clear" title="清空" v-show="sendNumber" @click="sendNumber='';errorType=0"></div>
              <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
            </div>
          </div>
          <p class="tips" v-if="service<2">提示：当前可邀请人数最大上限为{{maxInviteNum}}</p>
          <div class="bottom-btn loading-dot" v-if="creating">生成中<i>.</i><i>.</i><i>.</i></div>
          <div class="bottom-btn" @click="createQrcode" v-else>生成小程序码</div>
        </template>
        <template v-else>
          <div class="qrcode">
            <img :src="qrcode">
            <div class="downloadBtn" style="opacity:.8;cursor:not-allowed;" v-if="qrcodeDownload">已下载</div>
            <div @click="downloadQrcode" class="downloadBtn" v-else>下 载</div>
            <p>提示：被邀请人可通过扫描此小程序码进入评估，可在<router-link to="/amt/invite">邀请管理</router-link>中查看邀请详情或结束此邀请</p>
          </div>
        </template>
      </div>
    </el-dialog>
    <!-- 查看成员评估记录弹窗 -->
    <el-dialog
      :title="memberNow.realname+' 评估记录'"
      class="dialog-middle"
      :visible.sync="assessShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      center>
      <el-table
        :data="assessList"
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column label="姓名" min-width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-head" style="width:102px">
              <img :src="scope.row.invite_avatar || require('../../../assets/images/head.png')">
              <span>{{scope.row.invite_realname}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="加入时间" min-width="130" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.create_time,'y-m-d h:m')}}</span>
          </template>
        </el-table-column>
        
        <el-table-column label="评估状态" min-width="76" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status==2" class="blue">已完成</span>
            <span v-else class="red">未完成</span>
          </template>
        </el-table-column>
        
        <el-table-column label="完成时间" min-width="130" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.end_time,'y-m-d h:m') || '-'}}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="180" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status==2">
              <span class="btn" @click="preview(scope.row)">预览报告</span>
              <span class="btn" @click="downloadReport(scope.row)">下载报告</span>
            </div>
            <div v-else>
              <span class="btn-disable">预览报告</span>
              <span class="btn-disable">下载报告</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="assessList.length>2" ref="assessPage" @change="assessRecord" :total="assessTotal" :position="'page-botttom'"></pagination>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../../assets/part/page'
export default {
  components: {pagination,},
  data(){
    return {
      reg: this.$CNReg,         // 当前区号的手机号正则
      country_code: '86',       // 区号
      phone: '',                // 手机号码
      code: '',                 // 验证码
      errorMsg: '',             // 输入框错误信息
      errorType: 0,             // 1：姓名为空，2：手机号错误，3：邀请数错误
      service: 0,

      btnLoadType: 0,
      maxInviteNum: 0,
      inviteName: '',
      inviteShow: false,
      inviteType: 0,            // 
      sendNumber: '',           // 发出邀请数
      creating: false,
      qrcode: '',
      qrcodeDownload: false,    // 是否已经下载了小程序码

      memberList: [],
      memberTotal: 0,
      memberLoading: false,
      memberNow: {},
      keyword: '',

      assessShow: false,
      assessList: [],
      assessTotal: [],
      assessLoading: false,
    }
  },
  mounted(){
    this.getMember()
    this.getNumber()
  },
  methods:{
    getMember(per_page, page){
      this.memberLoading = true
      let ref = this.$refs.memberPage;
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$request({
        url:'/Amt/index',
        data: {
          keyword: this.keyword,
          per_page: per_page,
          page: page,
        },
        success: (data) => {
          if (data.code == 0) {
            this.memberTotal = data.data.total
            this.memberList = data.data.data
          } else if (data.code == 101) {
            this.$router.replace({path:'/login',query:{back:this.$route.path}})
            localStorage.clear();
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
        complete: () => this.memberLoading = false
      })
    },
    searchMember(e){
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(()=>{
        this.keyword = e.target.value
        this.getMember(null, 1)
      }, 600)
    },
    assessRecord(per_page, page, row){
      this.assessLoading = true
      let ref = this.$refs.assessPage
      per_page = ref ? ref.per_page : per_page || 20
      page = ref ? ref.page : page || 1
      this.$request({
        url:'/Amt/getEmployeeExam',
        data: {
          user_id: row ? row.user_id : this.memberNow.user_id,
          per_page: per_page,
          page: page,
        },
        success: (data) => {
          if (data.code == 0) {
            this.assessList = data.data.data
            this.assessTotal = data.data.total
            this.assessShow = true
            this.memberNow = row
          } else {
            this.$message({duration: 2000,message: data.msg})
          }
        },
        complete: () => this.assessLoading = false
      })
    },
    preview(row){
      let examId = row.exam_id || row.id
      let src = `${this.$mobileUrl}/#/AMT/report?exam_id=${examId}`
      window.open(src)
    },
    downloadReport(row){
      let examId = row.exam_id || row.id
      window.open(`${this.$baseRoot}/Amt/downReport?exam_id=${examId}`)
    },
    //获取可邀请数量
    getNumber(){
      this.$request({url: '/Amt/statistical',
        success: (data) => {
          if (data.code == 0) {
            this.maxInviteNum = data.data.may_invite_num
            this.service = data.data.service
          }
        },
      })
    },
    //手机号邀请新成员
    mobileInvite(){
      if (!this.inviteName) {
        this.setError(1,'请先输入姓名')
        this.$refs.inviteName.focus()
        return
      } else if (!this.phone) {
        this.setError(2,'请先输入手机号')
        this.$refs.phone.focus()
        return
      } else if (!this.reg.test(this.phone)) {
        this.setError(2,'手机号码格式不对')
        this.$refs.phone.focus()
        return
      }
      this.btnLoadType = 1
      this.$request({
        url: '/Amt/baseMobileInvite',
        data: {
          realname: this.inviteName,
          mobile: this.phone,
          country_code: this.country_code,
        },
        success: data => {
          if (data.code == 0) {
            this.$message({duration: 1000,message: '邀请成功', type:'success'})
            this.inviteShow = false
            this.getMember()
            this.getNumber()
          } else {
            this.$message({duration:2000, message:data.msg, type:'error'})
          }
        },
        complete: this.btnLoadType = 0
      })
    },
    //小程序码邀请
    createQrcode(){
      if (!this.sendNumber) {
        this.setError(3, '请输入邀请数')
        return
      } else if (this.sendNumber > this.maxInviteNum) {
        this.setError(3, '数量超出可邀请数')
        return
      }
      this.creating = true
      this.$request({
        url:'/Amt/codeOrgInvite',
        data: {invite_num: this.sendNumber},
        success: (data) => {
          if (data.code == 0) {
            this.getNumber()
            this.$request({
              url: this.$planetUrl+'/Common/createQrcode',
              data: {
                param: data.data.id + '=' + data.data.code,
                page: 'pages/AMT/inviteAccept/inviteAccept',
                width: 280,
              },
              success: (e) => {
                if (e.code == 0) {
                  this.inviteType = 2
                  this.qrcode = e.data.qrcode_path
                } else {
                  this.$message({duration: 2000, message: e.msg})
                }
              },
              complete: ()=>{
                this.creating = false
              }
            })
          } else {
            this.creating = false
            this.$message({duration: 2000, message: data.msg})
          }
        },
        error: ()=>{
          this.creating = false
          this.$message.error('网络错误，请稍后再试')
        }
      })
    },
    downloadQrcode(){
      if (this.qrcodeDownload == false) {
        window.open(`${this.$baseRoot}/Amt/downQrcode?qrcode_path=${this.qrcode}`)
        this.qrcodeDownload = true
      }
    },
    // 选择区号筛选手机号正则
    selectCountry(key){
      this.$countryList.forEach((item)=>{
        if(item.country_code==key){
          this.reg = this[item.key]
          return
        }
      })
    },
    // 监听输入内容
    inputChange(e, key = 'phone'){
      let text = e.target.value
      this[key] = text.replace(/\D/g,'')
      this.errorType = 0;

      if(key == 'sendNumber' && text.charAt(0) == '0')
        this.sendNumber = text.substring(1, text.length);
    },
    setError(type = 0, msg = ''){
      this.errorType = type
      this.errorMsg = msg
    },
    closeDialog(){
      this.inviteName = ''
      this.phone = ''
      this.sendNumber = null
      this.memberNow = {}
      this.btnLoadType = 0
      this.setError()
      this.qrcodeDownload = false
      this.inviteType = 0
    },
    // 切换 短信登录/账号密码登录
    toggle(type){
      if (this.inviteType==type)  return;
      else if (this.inviteType==2 && type==1) return
      this.inviteType = type
      this.errorType = 0
      this.inviteName = ''
      this.phone = ''
      this.sendNumber = null
    },
  },
}
</script>
<style lang="scss" scoped>
  .staff{
    min-width: 480px;
    .qrcode{
      img{
        width: 136px;
        height: 136px;
        display: block;
        margin: -10px auto 0;
      }
      .downloadBtn{
        border-radius: 4px;
        width: 100px;
        color: #fff;
        background: #6b6be5;
        text-align: center;
        line-height: 30px;
        height: 30px;
        margin: 20px auto;
        cursor: pointer;
      }
      p{
        color: #868686;
        a{color: #6b6be5;cursor: pointer;}
      }
    }
    .explain{
      color: #868686;
      margin: 40px 50px;
      line-height: 1.6;
      a{
        color: #6b6be5;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .bottom-btn{margin-bottom: 0}
    .upload-box{
      margin: 44px 50px 52px;
      position: relative;
      .upload{
        flex: 1;
        margin-left: 10px;
      }
    }
  }
</style>